body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.innerFormSection{
  padding: 0px 20px;
}

.formTitle{
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.confirmationImg{
  max-width: 130px;
}

.confirmationDateBox{
border-radius: 10px;
border: 2px solid #000;
min-width: 140px;
margin-top: 15px !important;
text-align: center;
}

.confirmationDateDayMonth{
  font-size: 55px;
  color: red;
}

.confirmationDateMonth{
  font-size: 20px;
}

.confirmationDateDayWeek{
  font-size: 16px;
}
.confirmationDateTime{
  font-size: 14px;
  padding-bottom: 8px;

}
.grecaptcha-badge{
  display: none;
}

@media only screen and (max-width: 500px) {
.ms-Panel-content{
  padding-left: 8px;
}

button.ms-Dropdown-item {
  font-size: 14px;
  line-height: 18px;
  min-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
}